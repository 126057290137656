



















































































































































































import { defineComponent, ref, onMounted, nextTick } from '@vue/composition-api'
import moment from 'moment'
import { api, framebus } from 'plugins'
import { endpoints, showError, urlPath, toCamelCase, frameBusEvent } from 'utils'

import { LoaderComponent, StepButton, AddButton, ConfirmDelete, ChooseNumber } from 'components'
import PreviewInvoiceDlg from '../Invoice/previewInvoiceDlg.vue'

const AssignGrower = defineComponent({
  components: {
    LoaderComponent,
    StepButton,
    AddButton,
    ConfirmDelete,
    ChooseNumber,
    PreviewInvoiceDlg
  },
  setup(props, { root }) {
    const { $toast, $route, $router, $store } = root
    const isFromCalendar = $route.query.fromCalendar
    const isLoading = ref(false)
    const isShowBottomSheet = ref(false)
    const showDelete = ref(false)
    const selectedDeliveryId = ref<number | null>(null)
    const showNumberDialog = ref(false)
    const previewInvoiceDlgValue = ref(false)
    const numberDialogTitle = ref('')
    const numberDialogValue = ref(0)
    const numberDialogKey = ref('')
    const growerDelivery = ref<any[]>([])
    const totalBoxes = ref(0)
    const totalStems = ref(0)
    const isAllDetailSent = ref(false)
    const isShowRemark = ref(false)
    const selectedRemark = ref('')
    const parentName = $store.state.common.settingMaster.find(
      (setting: any) => setting.key === 'name'
    )?.value
    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.date || moment(new Date()).format('YYYY-MM-DD')
    )
    // if go to page from calendar page, get date from url
    if (isFromCalendar) {
      auctionDate.value = $route.query.auctionDate
    }

    const nextAuctionDate = ref('')
    const date = ref('')

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        date.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }
    const modal = ref(false)
    const calTotal = () => {
      totalBoxes.value = 0
      totalStems.value = 0
      isAllDetailSent.value = true
      growerDelivery.value.forEach((delivery: any) => {
        totalBoxes.value += delivery.boxes || 0
        totalStems.value += delivery.stems || 0
        isAllDetailSent.value = isAllDetailSent.value && delivery.isSent
      })
    }
    const replaceUrl = (): void => {
      $router
        .replace({
          query: {
            auction_date: date.value,
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })
      const latestSelectedDates = {
        auctionDate: date.value.toString()
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
    }
    const getGrowerDelivery = async (enableScrollToPreviousPosition: boolean) => {
      // get position before reload
      const positionY = window.scrollY

      isLoading.value = true
      replaceUrl()
      try {
        const { data } = await api.get(`${endpoints.GROWER_DELIVERY}?auction_date=${date.value}`)
        growerDelivery.value = toCamelCase(data)
        calTotal()
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        isLoading.value = false

        // scroll to previous position
        if (enableScrollToPreviousPosition) {
          // need wait for the list was rendered
          nextTick(() => {
            window.scrollTo(0, positionY)
          })
        }
      }
    }

    const deleteDelivery = async (): Promise<boolean> => {
      try {
        await api.delete(`${endpoints.GROWER_DELIVERY}${selectedDeliveryId.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }
      return false
    }
    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteDelivery()
      }
      if (success) {
        getGrowerDelivery(true)
      }
      showDelete.value = false
      selectedDeliveryId.value = null
    }
    const openNumberDialog = (selectedId: number, stepBtnType: string) => {
      showNumberDialog.value = true
      selectedDeliveryId.value = selectedId as number
      numberDialogTitle.value = root.$t(`master.${stepBtnType}`) as string
      numberDialogKey.value = stepBtnType
      const selectedDelivery = growerDelivery.value.find(
        (delivery: any) => delivery.id === selectedDeliveryId.value
      )
      if (selectedDelivery) {
        numberDialogValue.value = selectedDelivery[stepBtnType] as number
      } else {
        numberDialogValue.value = 0
      }
    }
    const updateDelivery = async (action: string, value: number): Promise<void> => {
      if (action === 'ok') {
        isLoading.value = true
        let hasChange = false
        const requestBody: any = {}
        const selectedDelivery = growerDelivery.value.find(
          (delivery: any) => delivery.id === selectedDeliveryId.value
        )
        if (numberDialogKey.value === 'boxes' && value !== selectedDelivery.boxes) {
          hasChange = true
          requestBody.boxes = value
          if (selectedDelivery) {
            requestBody.stems = selectedDelivery.quantity
              ? selectedDelivery.quantity * value
              : selectedDelivery.stems
            requestBody.is_sent = false
          }
        } else if (numberDialogKey.value === 'stems' && value !== selectedDelivery.stems) {
          hasChange = true
          requestBody.stems = value
          requestBody.is_sent = false
        }
        if (hasChange) {
          try {
            await api.put(`${endpoints.GROWER_DELIVERY}${selectedDeliveryId.value}`, requestBody)
            $toast.success(root.$t('common.msg.update_success'))
          } catch (e) {
            showError(e, $toast, root.$t('common.msg.system_failure'))
          } finally {
            isLoading.value = false
          }
          getGrowerDelivery(true)
        } else {
          $toast.info(root.$t('common.msg.nothing_has_changed'))
        }
      }
      isLoading.value = false
      showNumberDialog.value = false
    }
    const createOrEditDelivery = (deliveryId: number | null = null): void => {
      $router
        .push({
          name: urlPath.ASSIGN_GROWER_FORM.name,
          params: {
            assignId: deliveryId ? deliveryId.toString() : 'create'
          },
          query: { auction_date: date.value, called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }
    onMounted(async () => {
      if (!$store.state.common.latestSelectedDates.auctionDate && !isFromCalendar)
        await getNextAuctionDate()
      else date.value = auctionDate.value

      await getGrowerDelivery(true)
    })
    return {
      modal,
      date,
      isLoading,
      growerDelivery,
      getGrowerDelivery,
      isShowBottomSheet,
      selectedDeliveryId,
      confirmDelete,
      showDelete,
      showNumberDialog,
      numberDialogTitle,
      numberDialogKey,
      openNumberDialog,
      numberDialogValue,
      updateDelivery,
      totalBoxes,
      totalStems,
      createOrEditDelivery,
      isAllDetailSent,
      isShowRemark,
      selectedRemark,
      parentName,
      previewInvoiceDlgValue
    }
  }
})

export default AssignGrower
