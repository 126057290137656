



























































import { defineComponent, ref, computed } from '@vue/composition-api'
import { Assign } from 'typings'
import { ConfirmDialog } from 'components'
import { api } from 'plugins'
import { showError, endpoints } from 'utils'

const PreviewInvoiceDlg = defineComponent({
  components: {
    ConfirmDialog
  },
  props: {
    dialogValue: {
      type: Boolean,
      default: false
    },
    auctionDate: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const currentState = ref(1)
    const invoicesData = ref([] as any)
    const isShowChooseNumber = ref(false)
    const showConfirmAutoFill = ref(false)
    const selectedAssignment = ref(1)
    const showEditRemark = ref(false)
    const remarkValue = ref('')
    const loading = ref(false)
    const bundleNoDialogValue = computed(
      () =>
        invoicesData.value.assignments.find((e: Assign) => e.id === selectedAssignment.value)
          ?.bundleNo || ''
    )
    const { $toast } = root
    const onClose = () => {
      emit('on-close')
      currentState.value = 1
    }
    const totalBoxes = computed(() => {
      let total = 0
      props.data.forEach((assignment: any) => {
        total += assignment.boxes
      })
      return total
    })
    const totalStems = computed(() => {
      let total = 0
      props.data.forEach((assignment: any) => {
        total += assignment.stems
      })
      return total
    })
    const onClickRightChevron = () => {
      if (currentState.value < 2) currentState.value += 1
    }
    const onClickLeftChevron = () => {
      if (currentState.value > 1) currentState.value -= 1
    }
    const onSave = async () => {
      if (props.data.length === 0) {
        $toast.error(root.$t('invoice_grower.grower_delivery_is_none'))
        return
      }
      try {
        await api.post(endpoints.GROWER_INVOICE_TRANSACTION, { auction_date: props.auctionDate })
        $toast.success(root.$t('invoice_grower.import_success'))
        emit('on-reload')
      } catch (e) {
        showError(e, $toast, root.$t('common.save_data_failed') as string)
      }
    }
    return {
      onClose,
      onClickLeftChevron,
      onClickRightChevron,
      onSave,
      currentState,
      invoicesData,
      isShowChooseNumber,
      showConfirmAutoFill,
      selectedAssignment,
      bundleNoDialogValue,
      showEditRemark,
      remarkValue,
      loading,
      totalBoxes,
      totalStems
    }
  }
})

export default PreviewInvoiceDlg
