

























import { defineComponent, ref } from '@vue/composition-api'
import { moment } from 'plugins'
import { fontResizeRatio } from 'utils'
import Store from '../../store'

interface QuickAccessItem {
  name: string
  text: string
  icon: string
}
const ChildGrowerHome = defineComponent({
  components: {},
  setup(props, { root }) {
    const isShowAction = false
    const thisDate = moment(new Date()).format('YYYY-MM-DD')
    const userInfo = ref({}) as any
    const fontRatioName = ref<any>()
    const items = [
      {
        name: 'assign_grower.title',
        text: 'assign_grower.title',
        icon: 'mdi-flower-outline'
      },
      {
        name: 'invoice_grower.title',
        text: 'invoice_grower.title',
        icon: 'mdi-clipboard-check-outline'
      },
      {
        name: 'master.title',
        text: 'product',
        icon: 'mdi-cog-outline'
      }
    ] as Array<QuickAccessItem>
    const getUser = async () => {
      userInfo.value = await Store.getters.getUserInfo
      if (userInfo.value.growerShortName) {
        fontRatioName.value = fontResizeRatio(userInfo.value.growerShortName, 85, 13)
      }
    }
    getUser()
    const goToPage = (page: string): void => {
      if (page !== root.$route.name) {
        if (page === 'assign_grower.title') {
          root.$router.push({
            name: page,
            params: { auctionDate: thisDate },
            query: { called: 'true' }
          })
        } else if (page === 'invoice_grower.title') {
          root.$router.push({
            name: page,
            params: {},
            query: { called: 'true' }
          })
        } else if (page === 'master.title') {
          root.$router.push({
            name: page,
            params: {},
            query: { called: 'true' }
          })
        } else {
          root.$router.push({
            name: page,
            query: { called: 'true' }
          })
        }
      }
    }
    return {
      goToPage,
      isShowAction,
      items,
      userInfo,
      fontRatioName
    }
  }
})
export default ChildGrowerHome
