






































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import moment from 'moment'
import { api, framebus } from 'plugins'
import { showError, endpoints, frameBusEvent, toCamelCase } from 'utils'
import { sumBy } from 'lodash'
import { StepButton } from 'components'
import PreviewInvoiceDlg from './previewInvoiceDlg.vue'

interface TotalAssign {
  boxes: number
  stems: number
}

const InvoiceGrower = defineComponent({
  components: {
    PreviewInvoiceDlg,
    StepButton
  },
  setup(props, { root }) {
    const { $toast, $route, $router, $store } = root
    const modal = ref(false)
    const date = ref('')
    const growerDelivery = ref<any[]>([])
    const totalAssign = ref<TotalAssign>({ boxes: 0, stems: 0 })
    const { settingMaster } = $store.state.common
    const companyName = settingMaster.find((e: any) => e.key === 'name').value
    const numberSubmit = ref(0)
    const previewInvoiceDlgValue = ref(false)
    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.date || moment(new Date()).format('YYYY-MM-DD')
    )

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        date.value = data.data.date
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getGrowerDelivery = async () => {
      try {
        const { data } = await api.get(`${endpoints.GROWER_DELIVERY}?auction_date=${date.value}`)
        growerDelivery.value = toCamelCase(data)
        totalAssign.value = {
          stems: sumBy(growerDelivery.value, 'stems'),
          boxes: sumBy(growerDelivery.value, 'boxes')
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const countNumberSubmit = async () => {
      try {
        const { data } = await api.get(
          `${endpoints.GROWER_INVOICE_TRANSACTION}count?auction_date=${date.value}`
        )
        numberSubmit.value = data.count
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const goToDate = () => {
      $router
        .replace({
          query: {
            auction_date: date.value,
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })
      const latestSelectedDates = {
        auctionDate: date.value.toString()
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
      getGrowerDelivery()
      countNumberSubmit()
    }

    const onSave = async () => {
      if (growerDelivery.value.length === 0) {
        $toast.error(root.$t('invoice_grower.grower_delivery_is_none'))
        return
      }
      try {
        await api.post(endpoints.GROWER_INVOICE_TRANSACTION, { auction_date: date.value })
        $toast.success(root.$t('invoice_grower.import_success'))
        numberSubmit.value += 1
      } catch (e) {
        showError(e, $toast, root.$t('common.save_data_failed') as string)
      }
    }

    const reload = async () => {
      await getGrowerDelivery()
      await countNumberSubmit()
    }

    onMounted(async () => {
      if (!$store.state.common.latestSelectedDates.auctionDate) {
        await getNextAuctionDate()
      } else date.value = auctionDate.value
      await getGrowerDelivery()
      await countNumberSubmit()
    })

    return {
      date,
      modal,
      goToDate,
      totalAssign,
      companyName,
      onSave,
      numberSubmit,
      reload,
      previewInvoiceDlgValue,
      growerDelivery
    }
  }
})

export default InvoiceGrower
